<template>
  <b10-base>
    <template
      v-if="isInsert"
    >
      <v-alert
        v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
        color="warning"
        dark
        small
      >
        Se creará un nuevo subsistema
      </v-alert>
      <ValidationProvider
        v-slot="{ errors }"
        name="idsubsis_idtsubsis"
        :rules="formRules.idsubsis_idtsubsis"
      >
        <b10-autocomplete
          v-model="form.idsubsis_idtsubsis"
          :items="subsisTsubsis"
          item-value="value"
          item-text="descripcion"
          label="Subsistema"
          clearable
          :error-messages="errors"
          @change="subsisTsubsisSelected"
        >
          <template
            #item="{ item, attrs, on }"
          >
            <v-list-item
              v-bind="attrs"
              :disabled="item.value.tipo === 'divider'"
              v-on="on"
            >
              <v-list-item-content
                v-if="item.value.tipo === 'divider'"
                :class="item.value.color"
                class="white--text pa-1 rounded"
              >
                {{ item.descripcion }}
              </v-list-item-content>
              <v-list-item-content
                v-else
              >
                {{ item.descripcion }}
              </v-list-item-content>
            </v-list-item>
          </template>
        </b10-autocomplete>
      </ValidationProvider>
      <v-text-field
        v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
        v-model="form.subsis_codigo"
        clearable
        flat
        label="Código del subsistema"
        :rules="formRules.subsis_codigo"
      />
      <v-text-field
        v-if="form.idsubsis_idtsubsis && form.idsubsis_idtsubsis.tipo === 'tsubsis'"
        v-model="form.subsis_descripcion"
        clearable
        flat
        label="Descripción del subsistema"
        :rules="formRules.subsis_descripcion"
      />
    </template>
    <ValidationProvider
      v-slot="{ errors }"
      name="tinclusion"
      :rules="formRules.tinclusion"
    >
      <b10-autocomplete
        v-show="showTinclusion"
        v-model="form.tinclusion"
        :items="tinclusion"
        item-value="id"
        item-text="descripcion"
        label="Tipo de inclusión"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <v-text-field
      v-model="form.descripcion"
      label="Descripcion"
      disabled
    />
    <ValidationProvider
      v-slot="{ errors }"
      name="unidades"
      :rules="formRules.unidades"
    >
      <v-text-field
        v-model.number="form.unidades"
        class="number-input"
        flat
        label="Unidades"
        type="Number"
        :error-messages="errors"
      />
    </ValidationProvider>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import { TINCLUSION } from '@/utils/consts'

export default {
  mixins: [formMixin],
  props: {
    idarticulo: {
      type: [String, Number],
      required: true,
    },
    idsolicitudMaterial: {
      type: [String, Number],
      required: true,
    },
  },
  data () {
    return {
      form: {
        idsubsis_idtsubsis: null,
        subsis_codigo: null,
        subsis_descripcion: null,
        descripcion: null,
        codigo: null,
        unidades: 1,
        tinclusion: null,
      },
      formRules: {
        unidaes: { required: true },
        tinclusion: { required: true },
        idsubsis_idtsubsis: { required: true },
      },
      tinclusion: [
        { id: TINCLUSION.instalacion, descripcion: 'Instalación' },
        { id: TINCLUSION.suministro, descripcion: 'Suministro' },
      ],
      subsisTsubsis: [],
      parteTrabajo: null,
      articulo: null,
      showTinclusion: false,
    }
  },
  async created () {
    if (this.isInsert) {
      this.articulo = await this.$offline.articulo.row(this.idarticulo)
      this.$set(this.form, 'descripcion', this.articulo.articulo.descripcion)
      this.$set(this.form, 'codigo', this.articulo.articulo.codigo)
      this.parteTrabajo = await this.$offline.solicitudMaterial.selectParteDeSolicitud(this.idsolicitudMaterial)
      this.inCodigosExistentes = await this.$offline.sistema.selectCodigosSubsis(this, this.parteTrabajo.orden_trabajo.idsistema)
      await this.mostrarOcultarTipoInclusion()
      this.subsisTsubsis = await this.$offline.ordenTrabajoSubsis.selectSubsisTsubsis(
        this.parteTrabajo.parte_trabajo.idorden_trabajo
      )
      let idsubsis = await this.$offline.subsis.selectSubsisPorDefecto(this.subsisTsubsis, this.idarticulo)
      // forzar evento
      this.$set(this.form, 'idsubsis_idtsubsis', idsubsis)
      this.subsisTsubsisSelected()
    }
    await this.loadForm()
  },
  methods: {
    async loadFormData (id) {
      const formData = await Data.selectFormData(this, id)
      this.form = formData
    },
    subsisTsubsisSelected () {
      if (
        this.form.idsubsis_idtsubsis &&
        this.form.idsubsis_idtsubsis.codigoPorDefecto &&
        this.form.idsubsis_idtsubsis.descripcionPorDefecto
      ) {
        this.$set(this.form, 'subsis_codigo', this.form.idsubsis_idtsubsis.codigoPorDefecto)
        this.$set(this.form, 'subsis_descripcion', this.form.idsubsis_idtsubsis.descripcionPorDefecto)
      } else {
        this.$set(this.form, 'subsis_codigo', null)
        this.$set(this.form, 'subsis_descripcion', null)
      }
    },
    async mostrarOcultarTipoInclusion () {
      if (this.isInsert) {
        this.tinclusion = await this.$offline.articulo.tinclusionArticulo(
          this.articulo.articulo.codigo,
          this.parteTrabajo.orden_trabajo.idttarifa,
          this.parteTrabajo.orden_trabajo.idbanco_precio,
          this.hasPermVerTarifas
        )
        const preguntar = await this.$offline.articulo.preguntarTinclusion(
          this.articulo.articulo.codigo,
          this.parteTrabajo.orden_trabajo.idttarifa,
          this.parteTrabajo.orden_trabajo.idbanco_precio
        )
        this.showTinclusion = preguntar.preguntar
        if (preguntar.tinclusion) {
          this.$set(this.form, 'tinclusion', preguntar.tinclusion)
        }
      } else {
        this.showTinclusion = false
      }
    },
  },
}
</script>
