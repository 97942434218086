var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b10-base',[(_vm.isInsert)?[(_vm.form.idsubsis_idtsubsis && _vm.form.idsubsis_idtsubsis.tipo === 'tsubsis')?_c('v-alert',{attrs:{"color":"warning","dark":"","small":""}},[_vm._v(" Se creará un nuevo subsistema ")]):_vm._e(),_c('ValidationProvider',{attrs:{"name":"idsubsis_idtsubsis","rules":_vm.formRules.idsubsis_idtsubsis},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{attrs:{"items":_vm.subsisTsubsis,"item-value":"value","item-text":"descripcion","label":"Subsistema","clearable":"","error-messages":errors},on:{"change":_vm.subsisTsubsisSelected},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"disabled":item.value.tipo === 'divider'}},'v-list-item',attrs,false),on),[(item.value.tipo === 'divider')?_c('v-list-item-content',{staticClass:"white--text pa-1 rounded",class:item.value.color},[_vm._v(" "+_vm._s(item.descripcion)+" ")]):_c('v-list-item-content',[_vm._v(" "+_vm._s(item.descripcion)+" ")])],1)]}}],null,true),model:{value:(_vm.form.idsubsis_idtsubsis),callback:function ($$v) {_vm.$set(_vm.form, "idsubsis_idtsubsis", $$v)},expression:"form.idsubsis_idtsubsis"}})]}}],null,false,2967963680)}),(_vm.form.idsubsis_idtsubsis && _vm.form.idsubsis_idtsubsis.tipo === 'tsubsis')?_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Código del subsistema","rules":_vm.formRules.subsis_codigo},model:{value:(_vm.form.subsis_codigo),callback:function ($$v) {_vm.$set(_vm.form, "subsis_codigo", $$v)},expression:"form.subsis_codigo"}}):_vm._e(),(_vm.form.idsubsis_idtsubsis && _vm.form.idsubsis_idtsubsis.tipo === 'tsubsis')?_c('v-text-field',{attrs:{"clearable":"","flat":"","label":"Descripción del subsistema","rules":_vm.formRules.subsis_descripcion},model:{value:(_vm.form.subsis_descripcion),callback:function ($$v) {_vm.$set(_vm.form, "subsis_descripcion", $$v)},expression:"form.subsis_descripcion"}}):_vm._e()]:_vm._e(),_c('ValidationProvider',{attrs:{"name":"tinclusion","rules":_vm.formRules.tinclusion},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b10-autocomplete',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTinclusion),expression:"showTinclusion"}],attrs:{"items":_vm.tinclusion,"item-value":"id","item-text":"descripcion","label":"Tipo de inclusión","clearable":"","error-messages":errors},model:{value:(_vm.form.tinclusion),callback:function ($$v) {_vm.$set(_vm.form, "tinclusion", $$v)},expression:"form.tinclusion"}})]}}])}),_c('v-text-field',{attrs:{"label":"Descripcion","disabled":""},model:{value:(_vm.form.descripcion),callback:function ($$v) {_vm.$set(_vm.form, "descripcion", $$v)},expression:"form.descripcion"}}),_c('ValidationProvider',{attrs:{"name":"unidades","rules":_vm.formRules.unidades},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"number-input",attrs:{"flat":"","label":"Unidades","type":"Number","error-messages":errors},model:{value:(_vm.form.unidades),callback:function ($$v) {_vm.$set(_vm.form, "unidades", _vm._n($$v))},expression:"form.unidades"}})]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }